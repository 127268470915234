<script>
import {mapGetters} from "vuex";
import {mdiFilter, mdiFilterOutline} from "@mdi/js";
import StartFilter from "@/components/Filters/StartFilter";
import EndFilter from "@/components/Filters/EndFilter";
import StatusFilter from "@/components/Filters/StatusFilter";
import NameFilter from "@/components/Filters/NameFilter";
import LocationFilter from "@/components/Filters/LocationFilter";
import EducationFilter from "@/components/Filters/EducationFilter";
import ProfileFilter from "@/components/Filters/ProfileFilter";
import ExperienceFilter from "@/components/Filters/ExperienceFilter";
import LanguagesFilter from "@/components/Filters/LanguagesFilter";

export default {
  name: "MenuFilters",
  components: {
    StatusFilter,
    EndFilter,
    StartFilter,
    NameFilter,
    LocationFilter,
    EducationFilter,
    ProfileFilter,
    ExperienceFilter,
    LanguagesFilter
  },
  data: function () {
    return {
      mdiFilterOutline: mdiFilterOutline,
      mdiFilter: mdiFilter,
      dialog: false,
    }
  },
  computed: {
    ...mapGetters("Filters", {
      isFiltered: "isFiltered"
    }),
  },
  methods: {
    clearFilter() {
      this.$store.commit("Filters/CLEAR")
      setTimeout(this.updateFilters,500)
    },
    updateFilters() {
      this.dialog = false
      this.$emit("update-filters")
    }
  },
}
</script>

<template>
  <v-dialog
    v-model="dialog"
    transition="scale-transition"
    width="290"
    style="border-radius: 10px;"
    persistent
    scrollable
  >
    <template #activator="{on, attrs}">
      <v-scroll-x-reverse-transition hide-on-leave>
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            v-if="isFiltered > 0"
            :content="isFiltered"
            bottom
            overlap
          >
            <v-icon
              color="primary"
            >
              {{ mdiFilter }}
            </v-icon>
          </v-badge>
          <v-icon
            v-if="isFiltered === 0"
          >
            {{ mdiFilterOutline }}
          </v-icon>
        </v-btn>
      </v-scroll-x-reverse-transition>
    </template>
    <v-card style="padding: 1px;">
      <v-card-title>{{ $t('pages.admin.filters.title') }}</v-card-title>
      <v-card-text style="height: 300px;">
        <StartFilter />
        <EndFilter />
        <StatusFilter />
        <NameFilter />
        <LocationFilter />
        <EducationFilter />
        <ProfileFilter />
        <ExperienceFilter />
        <LanguagesFilter />
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          small
          @click="clearFilter"
        >
          {{ $t('pages.admin.filters.btn-reset') }}
        </v-btn>
        <v-spacer />
        <v-btn
          text
          small
          @click="updateFilters"
        >
          {{ $t('pages.admin.filters.btn-ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>