<script>
export default {
  name: "StartFilter",
  data: ()=>({
    menu: false
  }),
  computed: {
    model: {
      get() {
        return this.$store.state["Filters"].dateBegin;
      },
      set(value) {
        this.$store.commit("Filters/BEGIN", value);
        this.$emit("update")
      }
    },
    min: {
      get() {
        return this.$store.state["Filters"].minDate;
      }
    },
    max: {
      get() {
        return this.$store.state["Filters"].dateEnd;
      }
    },
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
  },
}
</script>

<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="290px"
  >
    <template #activator="{on, attrs}">
      <v-text-field
        v-model="model"
        :label="$t('pages.admin.filters.begin')"
        hide-details
        clearable
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      ref="picker"
      v-model="model"
      :max="max"
      @change="save"
    />
  </v-menu>
</template>

<style scoped>
</style>