<script>
import i18n from "@/plugins/i18n";

export default {
	name: "StatusFilter",
  data: function() {
    return {
      items: [
        {
          text: i18n.t("pages.admin.statuses.pending"),
          value: "pending",
        },
        {
          text: i18n.t("pages.admin.statuses.reserved"),
          value: "reserved",
        },
        {
          text: i18n.t("pages.admin.statuses.accepted"),
          value: "accepted",
        },
        {
          text: i18n.t("pages.admin.statuses.rejected"),
          value: "rejected",
        },
      ]
    };
  },
	computed: {
		model: {
			get() {
				return this.$store.state["Filters"].statuses;
			},
			set(value) {
				this.$store.commit("Filters/STATUS", value);
        this.$emit("update")
			}
		},
	},
}
</script>

<template>
  <v-select
    v-model="model"
    :label="$t('pages.admin.filters.status')"
    hide-details
    clearable
    :items="items"
    multiple
    :menu-props="{'offset-y': true, 'closeOnClick': true}"
  >
    <template #selection="{item, index}">
      <v-chip
        v-if="index === 0 && model.length === 1"
        small
        :color="item.color"
      >
        {{ item.text }}
      </v-chip>
      <span
        v-if="index === 0 && model.length > 1"
      >{{ model.length }} выбрано</span>
    </template>
  </v-select>
</template>