<script>
import i18n from "@/plugins/i18n";

export default {
  name: "LabelProfile",
  props: {
    value: {
      type: String,
      required: true,
    }
  },
  computed: {
    text() {
      switch (this.value) {
        case "locksmith":
          return i18n.t("pages.admin.profiles.locksmith");
        case "junior":
          return i18n.t("pages.admin.profiles.junior");
        case "welder":
          return i18n.t("pages.admin.profiles.welder");
        case "professional":
          return i18n.t("pages.admin.profiles.professional");
        default:
          return this.value;
      }
    }
  }
}
</script>

<template>
  <span>{{ text }}</span>
</template>
