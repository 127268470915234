<script>
export default {
	name: "StatusFilter",
  data: function() {
    return {};
  },
	computed: {
		model: {
			get() {
				return this.$store.state["Filters"].locations;
			},
			set(payload) {
				this.$store.commit("Filters/LOCATION", payload);
        this.$emit("update")
			}
		},
	},
}
</script>

<template>
  <v-text-field
    v-model="model"
    :label="$t('pages.admin.filters.location')"
    hide-details
  />
</template>
