var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"auto-select-first":"","items":_vm.users,"loading":_vm.loading,"search-input":_vm.search,"small-chips":"","item-text":"email","item-value":"id","label":_vm.$t('pages.admin.filters.name'),"multiple":"","hide-details":"","clearable":"","cache-items":"","menu-props":{maxHeight: 200}},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-action',[_c('v-simple-checkbox',_vm._g({directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],attrs:{"value":attrs.inputValue}},on))],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(("" + (parent.genFilteredText(item.email))))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.model.length > 0 && index === 0)?_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(((_vm.model.length) + " выбрано"))+" ")]):_vm._e()]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})}
var staticRenderFns = []

export { render, staticRenderFns }