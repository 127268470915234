<script>
import i18n from "@/plugins/i18n";
import ApiSecure from "@/services/api/api-secure";
import {mapActions} from "vuex";

export default {
  name: "LabelStatus",
  props: {
    value: {
      required: true,
      type: String,
    },
    changeStatus: {
      type: Boolean,
    },
    applicationId: {
      type: String,
      required: true,
    }
  },
  data: function () {
    return {
      loading: false,
      statuses: ["pending", "reserved", "accepted", "rejected"]
    }
  },
  computed: {
    click() {
      return this.changeStatus ? "click" : null
    },
    text() {
      switch (this.value) {
        case "pending":
          return i18n.t("pages.admin.statuses.pending");
        case "reserved":
          return i18n.t("pages.admin.statuses.reserved");
        case "accepted":
          return i18n.t("pages.admin.statuses.accepted");
        case "rejected":
          return i18n.t("pages.admin.statuses.rejected");
        default:
          return this.value;
      }
    }
  },
  methods: {
    ...mapActions("Admin", {
      formatApplication: "formatApplication"
    }),
    async onClick() {
      this.loading=true
      const i = this.statuses.findIndex((s)=>s===this.value)
      const result = await ApiSecure.put("/application/status/change", {
        applicationId: this.applicationId,
        status: this.statuses[(i+1) % this.statuses.length]
      })
      this.$emit("update", await this.formatApplication(result))
      this.loading=false
    }
  }
}
</script>

<template>
  <v-chip
    :disabled="loading"
    @[click]="onClick"
  >
    {{ text }}
  </v-chip>
</template>

<style lang="scss" scoped>
.v-chip {
  &::before {
    background-color: transparent;
  }
}
</style>