<script>
export default {
	name: "EndFilter",
	data: ()=>({
		menu: false
	}),
	computed: {
		min: {
			get() {
				return this.$store.state["Filters"].dateBegin;
			}
		},
		max: {
			get() {
				return this.$store.state["Filters"].maxDate;
			}
		},
		model: {
			get() {
				return this.$store.state["Filters"].dateEnd;
			},
			set(value) {
				this.$store.commit("Filters/END", value);
        this.$emit("update")
			}
		},
	},
	watch: {
		menu (val) {
			val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
		},
	},
	methods: {
		save (date) {
			this.$refs.menu.save(date)
		},
	}
}
</script>

<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="290px"
  >
    <template #activator="{on, attrs}">
      <v-text-field
        v-model="model"
        :label="$t('pages.admin.filters.end')"
        readonly
        clearable
        hide-details
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      ref="picker"
      v-model="model"
      :min="min"
      @change="save"
    />
  </v-menu>
</template>

<style scoped>

</style>