<script>
import {mapState} from "vuex";

export default {
	name: "StatusFilter",
  data: function() {
    return {
      loading: false,
      search: null,
    };
  },
	computed: {
    ...mapState("Filters", {
      users: "userItems"
    }),
		model: {
			get() {
				return this.$store.state["Filters"].userIds;
			},
			set(payload) {
        this.search = null;
				this.$store.commit("Filters/USER", payload);
        this.$emit("update")
			}
		},
	},
  watch: {
    async search(query) {
      if (this.loading) return;
      await this.searchUser(query);
    }
  },
  async created() {
    await this.searchUser("");
  },
  methods: {
    async searchUser(query) {
      this.loading = true;
      await this.$store.dispatch("Filters/fetchUsers", query);
      this.loading = false;
    }
  }
}
</script>

<template>
  <v-autocomplete
    v-model="model"
    auto-select-first
    :items="users"
    :loading="loading"
    :search-input.sync="search"
    small-chips
    item-text="email"
    item-value="id"
    :label="$t('pages.admin.filters.name')"
    multiple
    hide-details
    clearable
    cache-items
    :menu-props="{maxHeight: 200}"
  >
    <template #item="{parent, item, on, attrs}">
      <v-list-item-action>
        <v-simple-checkbox
          v-ripple="false"
          :value="attrs.inputValue"
          v-on="on"
        />
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title
          v-html="`${parent.genFilteredText(item.email)}`"
        />
      </v-list-item-content>
    </template>
    <template #selection="{item, index}">
      <span
        v-if="model.length > 0 && index === 0"
        class="mr-1"
      >
        {{ `${model.length} выбрано` }}
      </span>
    </template>
  </v-autocomplete>
</template>
