<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {mdiCloseCircleOutline, mdiFormatListChecks, mdiRefresh, mdiDownload} from "@mdi/js";
import i18n from "@/plugins/i18n";
import LabelStatus from "@/components/LabelStatus";
import LabelProfile from "@/components/LabelProfile";
import MenuFilters from "@/components/MenuFilters";
import ApiDoc from "@/services/api/api-doc";
export default {
  name: "Admin",
  components: {
    MenuFilters,
    LabelProfile,
    LabelStatus
  },
  data: function() {
    return {
      dialog: false,
      loading: false,
      applicationToDisplay: null,
      downloading: false,
      downloadingReport: false,
      icons: {
        closeCircleOutline: mdiCloseCircleOutline,
        refresh: mdiRefresh,
        listCheck: mdiFormatListChecks,
        download: mdiDownload,
      }
    };
  },
  computed: {
    ...mapState("Admin", {
      current: "current",
      totalLength: "totalLength",
      updateUsersStatus: "updateUsersStatus",
    }),
    ...mapGetters("Admin", {
      applications: "getApplications",
    }),
    ...mapGetters("Filters", {
      filters: "getFilters",
    }),
    headers() {
      return [
        {
          text: i18n.t("pages.admin.headers.name"),
          value: "name",
        },
        {
          text: i18n.t("pages.admin.headers.profileType"),
          value: "profileType",
        },
        {
          text: i18n.t("pages.admin.headers.email"),
          value: "email",
        },
        {
          text: i18n.t("pages.admin.headers.createdDate"),
          value: "createdDate",
        },
        {
          text: i18n.t("pages.admin.headers.finishStatus"),
          value: "finishStatus",
        },
        {
          text: i18n.t("pages.admin.headers.foreignLanguages"),
          value: "foreignLanguages",
        },
        {
          text: i18n.t("pages.admin.headers.location"),
          value: "location",
        },
        // {
        //   text: i18n.t("pages.admin.headers.number"),
        //   value: "number",
        // },
        {
          text: i18n.t("pages.admin.headers.status"),
          value: "status",
        },
        {
          text: i18n.t("pages.admin.headers.testResult"),
          value: "testResult",
        },
        {
          text: i18n.t("pages.admin.headers.testTime"),
          value: "testTime",
        },
      ];
    }
  },
  async created() {
    await this.updateApplicationsList();
  },
  methods: {
    ...mapActions("Admin", {
      fetchApplications: "fetchApplications",
    }),
    async updateApplicationsList() {
      this.loading = true;
      await this.fetchApplications();
      this.loading = false;
    },
    async onOpenApplication(_, {item}) {
      this.applicationToDisplay = item
      await this.$nextTick()
      this.dialog = true
    },
    async updateApplication(payload) {
      this.applicationToDisplay = payload
    },
    async download() {
      this.downloading = true;
      const link = await ApiDoc.get(`/application/${this.applicationToDisplay.id}/download`);
      link.download = this.applicationToDisplay.id;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
        this.downloading = false;
      }, 200);
    },
    async downloadReport() {
      this.downloadingReport = true;
      const link = await ApiDoc.post(`/application/list/download`, this.filters);
      link.download = this.$t("pages.admin.download-report");
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
        this.downloadingReport = false;
      }, 200);
    }
  },
}
</script>

<template>
  <v-container
    fluid
    fullscreen
    class="ma-0 pa-0"
  >
    <v-data-table
      :items="applications"
      :headers="headers"
      :loading="loading"
      disable-filtering
      disable-sort
      disable-pagination
      hide-default-footer
      @dblclick:row="onOpenApplication"
    >
      <template #item.finishStatus="{item}">
        <v-simple-checkbox
          disabled
          :value="item.finishStatus"
          class="checkbox"
        />
      </template>
      <template #item.status="{item}">
        <LabelStatus
          :value="item.status"
          :application-id="item.id"
        />
      </template>
      <template #item.profileType="{item}">
        <LabelProfile :value="item.profileType" />
      </template>
      <template #top>
        <v-toolbar
          elevation="0"
          class="admin-toolbar mb-3"
        >
          <v-toolbar-title>
            {{ $t("pages.admin.title") }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            :disabled="loading"
            @click="updateApplicationsList"
          >
            <v-icon>{{ icons.refresh }}</v-icon>
          </v-btn>
          <v-btn
            icon
            :disabled="downloadingReport"
            @click="downloadReport"
          >
            <v-icon>{{ icons.download }}</v-icon>
          </v-btn>
          <MenuFilters @update-filters="updateApplicationsList" />
        </v-toolbar>
      </template>
      <template #loading>
        <v-icon
          class="mb-2 mt-5 mx-auto"
          x-large
        >
          {{ icons.refresh }}
        </v-icon>
        <p class="mb-2 mt-5 mx-auto">
          {{ $t("loading-data") }}
        </p>
      </template>
      <template #no-data>
        <v-icon
          class="mb-2 mt-5 mx-auto"
          x-large
        >
          {{
            icons.closeCircleOutline
          }}
        </v-icon>
        <p class="mb-5">
          {{ $t("no-data") }}
        </p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500px"
    >
      <v-card
        v-if="applicationToDisplay"
        :height="500"
        :tile="$vuetify.breakpoint.xsOnly"
      >
        <v-card-title>Заявка #{{ applicationToDisplay.id }}</v-card-title>
        <v-card-subtitle class="pb-0 pt-2">
          <LabelStatus
            :value="applicationToDisplay.status"
            change-status
            :application-id="applicationToDisplay.id"
            @update="updateApplication"
          />
        </v-card-subtitle>
        <v-card-text>
          <v-sheet
            elevation="1"
            rounded
            class="mt-4 pa-3"
          >
            <p><strong>{{ applicationToDisplay.author.name }}</strong></p>
            <p>{{ applicationToDisplay.author.email }}</p>
            <LabelProfile :value="applicationToDisplay.profileType" />
          </v-sheet>
          <v-sheet
            elevation="1"
            rounded
            class="mt-4 pa-3"
          >
            <p>Тест пройден: {{ applicationToDisplay.finishStatus ? " Да" : " Нет" }}</p>
            <p>Результат: {{ applicationToDisplay.testResult }}</p>
            <p>Время: {{ applicationToDisplay.testTime }}</p>
          </v-sheet>
        </v-card-text>
        <v-card-actions>
          <v-btn
            depressed
            tile
            large
            :loading="downloading"
            @click="download"
          >
            Скачать отчет
          </v-btn>
          <v-spacer />
          <v-btn
            depressed
            tile
            large
            color="primary"
            @click="dialog = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style lang="scss">
.checkbox {
  .v-input--selection-controls__input {
    margin: 0
  }
}
</style>
